const burgerMenu = document.querySelector(".burger-menu");
const navMenu = document.querySelector(".nav-menu");

burgerMenu.addEventListener("click", function () {
  navMenu.classList.toggle("opened");
});

const swiperSliderElement = document.querySelector(".swiperSlider");

if (swiperSliderElement) {
  const swiper = new Swiper(".swiperSlider", {
    loop: true,
    autoplay: {
      delay: 5000,
    },
    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
}
document.getElementById("currentYear").innerHTML = new Date().getFullYear();
